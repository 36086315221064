/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;

}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.visualizeFeedbackQuestionContainerChild {
    background-color: #cedce2;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 20px;
    box-shadow: 0 0 3px gray;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90% !important;
    float: right;
}

.visualizeFeedbackQuestionContainer {
    background-color: #cedce2;
    padding: 20px;
    margin-top: 30px;
    border-radius: 20px;
    box-shadow: 0 0 3px gray;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.titleText {
    color: #1C4857;
}

.visualizeFeedbackQuestionHeaderDiv {
    width: 99% !important;
    color: #1C4857;
    border-bottom: 1px solid dimgrey;
    border-radius: 10px;
    margin-bottom: 20px;
}

.visualizeFeedbackQuestionBodyDiv {
    color: #1C4857;
    margin-right: auto;
    margin-left: 5px;
}

.visualizeFeedbackQuestionFooterDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.visualizeFeedbackButtons {
    border-radius: 10px !important;
    min-width: 120px;
}

.visualizeFeedbackTextInput {
    height: 30px;
    border-radius: 10px;
    border-style: none;
    box-shadow: 0 0 3px gray;
    color: #4f555b;
}

.visualizeFeedbackNumInput {
    height: 30px;
    border-radius: 10px;
    border-style: none;
    box-shadow: 0 0 3px gray;
    color: #4f555b;
}

.visualizeFeedbackCheckInput {

    border-style: none !important;
    box-shadow: 0 0 3px gray;
    color: #4f555b;

}
