:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #5867dd;
    --secondary: #e1e1ef;
    --success: #1dc9b7;
    --info: #5578eb;
    --warning: #ffb822;
    --danger: #fd397a;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 1024px;
    --breakpoint-xl: 1399px;

    /*--font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;*/
}
a{
    /*color:white!important;*/
}

html{
    position: relative;
    min-height: 100%;
     line-height: 1.15;
    -webkit-text-size-adjust: 100%;
     -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body{
    display: flex;
    flex-direction: column;
    color: #646c9a;
    background: #f2f3f8!important;
    height: 100%;
    margin: 0px;
    padding: 0px;
    font-size: 13px !important;
    font-weight: 300;
    font-family: Poppins, Helvetica, sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 60px;

}

.container {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto
}

.kt-portlet {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    background-color: #ffffff;

}
.kt-portlet .kt-portlet__head {
    transition: height 87.3s;
}

.kt-portlet .kt-portlet__head {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    padding: 0 25px;
    border-bottom: 1px solid #ebedf2;
    min-height: 50px;
}
.kt-portlet .kt-portlet__head .kt-portlet__head-toolbar {
    display: flex;
    align-items: center;
    align-content: flex-end;
}
.kt-portlet .kt-portlet__head .kt-portlet__head-toolbar .kt-portlet__head-wrapper {
    display: flex;
    align-items: center;
}
.kt-portlet .kt-portlet__body {
    display: flex;
    flex-direction: column;
    padding: 25px;
    width: 100%;
    overflow-x: auto;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    background-color: transparent;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}
.text-uppercase {
    text-transform: uppercase !important;
}
.text-center {
    text-align: center !important;
}
/*.pagination {*/
/*    display: flex;*/
/*    padding-left: 0;*/
/*    list-style: none;*/
/*    border-radius: 0.25rem;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*}*/
.table-striped tbody tr:nth-of-type(odd) {
    background-color: white!important;
}
.page-footer{
    /*position: absolute;*/
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #2e5b6e;
    color: white;
    text-align: center;
    float: bottom;
}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }

.btn-block {
    display: block;
    width: 100%;
}
.btn-primary {
    color: #fff !important;
    background-color: #007bff !important;
    border-color: #007bff;
}
.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0 !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    align-items: center;
    background: transparent;
    outline: none !important;
    margin: 0;
    font-family: Poppins, Helvetica, sans-serif !important;
}

.btn-success {
    color: #fff;
    background-color: #28a745 !important;
    border-color: #28a745;
}

.btn-dark {
    color: #fff;
    background-color: #343a40 !important;
    border-color: #343a40;
}

.btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0 !important;
}

.btn-danger {
    color: #fff;
    background-color: #dc3545 !important;
    border-color: #dc3545;
}
.btn-info {
    color: #fff;
    background-color: #17a2b8 !important;
    border-color: #17a2b8;
}
.btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
}
.btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0!important;
}
.btn-block {
    display: block;
    width: 100%;
}
.btn.btn-elevate {
    transition: all 0.3s ease 0s;
}

.btn.btn-brand.btn-elevate {
    box-shadow: 0px 4px 16px 0px rgba(44, 119, 244, 0.15);
}

.btn{
    background: transparent;
    outline: none !important;
}
.btn-preview{
    color: #fff!important;
    background-color: #555!important;
    border-color: #555!important;
}

.img-icon{
    width: 40px;
    height: 40px;
}
.myCheckbox{


}

input, button, select, optgroup, textarea {
    font-family: sans-serif!important;
}
@media (min-width: 1429px){
    .kt-container.kt-container--fluid {
        width: 100%;
        margin: 0px;
    }
}

@media(max-width: 1440px) {
    .modal-body{
        width: 150% !important;
    }
    .img-icon{
        width: 40px !important;
        height: 40px !important;
    }


}

@media (min-width: 1025px){
    .kt-container {
        padding: 20px 20px;
    }
    .kt-grid > .kt-grid__item {
        max-width: 100%;
    }
    .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item {
        flex: 0 0 auto;
    }
    .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--fluid-desktop {
        flex: 1 1 auto;
        -ms-flex: 1 0 0px;
        min-width: 0;
    }
    .kt-form.kt-form--label-right .form-group label:not(.kt-checkbox):not(.kt-radio):not(.kt-option) {
        text-align: right; }

    .kt-grid.kt-grid--ver-desktop.kt-grid--desktop {
        display: flex;
        flex-direction: row;
    }
    .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--fluid-desktop {
        flex: 1 1 auto;
        -ms-flex: 1 0 0px;
        min-width: 0;
    }
}


@media(max-width: 992px) {
    .kt-portlet, .kt-portlet--mobile {
        overflow: scroll !important;
    }
    .img-icon{
        width: 40px !important;
        height: 40px !important;
    }
    .kt-container {
        padding: 100px 20px;
    }

}


@media (max-width: 768px) {
    .kt-portlet, .kt-portlet--mobile {
        overflow: scroll !important;
    }
    .img-icon{
        width: 40px !important;
        height: 40px !important;
    }
    .kt-container {
        padding: 100px 20px;
    }

    .kt-grid > .kt-grid__item {
        max-width: 100%;
    }

}

@media (min-width: 769px) {

}




.kt-checkbox > span {
    border: 1px solid #d1d7e2; }
input[type="radio"],
input[type="checkbox"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0; }

button, input{
    overflow: visible;
}


.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 0;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }

img {
    vertical-align: middle;
    border-style: none;
}
button, [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
}
button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
    cursor: pointer;
}

.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) {
    display: flex;
    flex-direction: row;
}

pre,
code,
kbd,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em; }

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto; }

figure {
    margin: 0 0 1rem; }

img {
    vertical-align: middle;
    border-style: none; }


svg {
    overflow: hidden;
    vertical-align: middle; }

table {
    border-collapse: collapse; }

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #74788d;
    text-align: left;
    caption-side: bottom; }

th {
    text-align: inherit; }

label {
    display: inline-block;
    margin-bottom: 0.5rem; }

button {
    border-radius: 0; }

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }

button,
input {
    overflow: visible; }

button,
select {
    text-transform: none; }

select {
    word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none; }

input[type="radio"],
input[type="checkbox"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    -webkit-appearance: listbox; }

textarea {
    overflow: auto;
    resize: vertical; }

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0; }

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal; }

progress {
    vertical-align: baseline; }

pre {
    display: block;
    font-size: 70%!important;
    color: #212529; }
pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto; }

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button; }


.kt-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    text-align: left;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
.kt-checkbox.kt-checkbox--disabled {
    opacity: 0.8;
    cursor: not-allowed; }
.kt-checkbox > input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
.kt-checkbox > span {
    background: none;
    position: absolute;
    top: 1px;
    left: 0;
    height: 18px;
    width: 18px; }

.kt-checkbox > span:after {
    content: '';
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    margin-left: -2px;
    margin-top: -6px;
    width: 5px;
    height: 10px;
    border-width: 0 2px 2px 0/*rtl:ignore*/ !important;
    -webkit-transform: rotate(45deg)/*rtl:ignore*/;
    transform: rotate(45deg)/*rtl:ignore*/; }

.kt-checkbox > input:checked ~ span {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: none; }
.kt-checkbox > input:checked ~ span:after {
    display: block; }
.kt-checkbox:hover > input:not([disabled]):checked ~ span,
.kt-checkbox > input:checked ~ span {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
.kt-checkbox > input:disabled ~ span {
    opacity: 0.6;
    pointer-events: none; }
.kt-checkbox.kt-checkbox--solid > span {
    border: 1px solid transparent; }
.kt-checkbox.kt-checkbox--solid:hover > input:not([disabled]) ~ span,
.kt-checkbox.kt-checkbox--solid > input:focus ~ span {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
.kt-checkbox.kt-checkbox--square > span {
    border-radius: 0; }
.kt-checkbox.kt-checkbox--bold > span {
    border-width: 2px !important;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }

.kt-checkbox{
     margin-top: 0.3rem;
      margin-bottom: 0;
}


/*   tukaa  */


.form-inline .kt-checkbox {
    margin-left: 15px;
    margin-right: 15px; }
.kt-checkbox.kt-checkbox--single {
    width: 18px;
    height: 18px; }
.kt-checkbox.kt-checkbox--single > span {
    top: 0px; }
th > .kt-checkbox.kt-checkbox--single,
td > .kt-checkbox.kt-checkbox--single {
    right: -5px; }
.input-group .kt-checkbox {
    margin-bottom: 0 !important;
    padding-left: 0; }

.kt-checkbox-list {
    padding: 0 0; }
.form-horizontal .form-group .kt-checkbox-list {
    padding-top: 0; }
.kt-checkbox-list .kt-checkbox {
    text-align: left;
    display: block; }
.kt-checkbox-list .kt-checkbox:last-child {
    margin-bottom: 5px; }

.kt-checkbox-inline {
    padding: 0 0; }
.kt-checkbox-inline .kt-checkbox {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 5px; }
.kt-checkbox-inline .kt-checkbox:last-child {
    margin-right: 0; }

.form-group.row .kt-checkbox-inline {
    margin-top: 0.75rem; }

.form-group.row .kt-checkbox-list {
    margin-top: 2px; }

.kt-checkbox.kt-checkbox--disabled {
    opacity: 0.7; }

.kt-checkbox > span {
    border: 1px solid #d1d7e2; }
.kt-checkbox > span:after {
    border: solid #bfc7d7; }

.kt-checkbox > input:disabled ~ span:after {
    border-color: #c8cfdd; }

.kt-checkbox > input:checked ~ span {
    border: 1px solid #c8cfdd; }

.kt-checkbox.kt-checkbox--bold > input:checked ~ span {
    border: 2px solid #c8cfdd; }

.kt-checkbox > input:disabled ~ span {
    opacity: 0.6; }

.kt-checkbox.kt-checkbox--solid > span {
    background: #e4e8ee;
    border: 1px solid transparent !important; }
.kt-checkbox.kt-checkbox--solid > span:after {
    border: solid #99a6bf; }

.kt-checkbox.kt-checkbox--solid > input:focus ~ span {
    border: 1px solid transparent !important; }

.kt-checkbox.kt-checkbox--solid > input:checked ~ span {
    background: #dee2ea; }

.kt-checkbox.kt-checkbox--tick > span {
    background: #e4e8ee;
    border: 1px solid transparent !important; }
.kt-checkbox.kt-checkbox--tick > span:after {
    border: solid #99a6bf; }

.kt-checkbox.kt-checkbox--tick > input:focus ~ span {
    border: 1px solid transparent !important; }

.kt-checkbox.kt-checkbox--tick > input:checked ~ span {
    border: 1px solid transparent !important;
    background: #e4e8ee; }
.kt-checkbox.kt-checkbox--tick > input:checked ~ span:after {
    border: solid #dee2ea; }


.input[type="radio"], .input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}
.input-group-text input[type="checkbox"] {
    margin-top: 0; }
.btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"]{
    position: absolute;
   clip: rect(0, 0, 0, 0);
    pointer-events: none;
}
.btn.btn-pill {
    border-radius: 2rem;
}

.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
.kt-grid-page{

    margin:0px;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}

.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--fluid {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto; }

.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile).kt-grid--root {
    -webkit-box-flex: 1;
    flex: 1;
    -ms-flex: 1 0 0px; }
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile).kt-grid--root {
    -webkit-box-flex: 1;
    flex: 1;
    -ms-flex: 1 0 0px; }
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; }
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--fluid {
    -webkit-box-flex: 1;
    flex: 1 auto;
    -ms-flex: 1 0 0px;
    min-width: 0; }
@media screen\0 {
    .kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--fluid {
        min-width: none; } }
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }

.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile).kt-grid--root {
    -webkit-box-flex: 1;
    flex: 1;
    -ms-flex: 1 0 0px; }

.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile).kt-grid--root {
    -webkit-box-flex: 1;
    flex: 1;
    -ms-flex: 1 0 0px; }
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none; }
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--fluid {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto; }
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }


.kt-quick-panel--right .kt-quick-panel {
    right: -445px;
    left: auto; }
.kt-quick-panel--right .kt-quick-panel.kt-quick-panel--on {
    -webkit-transition: left 0.3s ease, right 0.3s ease;
    transition: left 0.3s ease, right 0.3s ease;
    right: 0;
    left: auto; }
.kt-quick-panel--right .kt-quick-panel-close {
    left: auto;
    right: -25px; }
.kt-quick-panel--on.kt-quick-panel--right .kt-quick-panel-close {
    left: auto;
    right: 399px; }

.kt-demo-panel--right .kt-demo-panel {
    right: -370px;
    left: auto; }
.kt-demo-panel--right .kt-demo-panel.kt-demo-panel--on {
    -webkit-transition: left 0.3s ease, right 0.3s ease;
    transition: left 0.3s ease, right 0.3s ease;
    right: 0;
    left: auto; }

.kt-demo-panel--right .kt-demo-panel-close {
    left: auto;
    right: -25px; }

.kt-demo-panel--on.kt-demo-panel--right .kt-demo-panel-close {
    left: auto;
    right: 324px; }

.kt-offcanvas-panel--right .kt-offcanvas-panel {
    right: -445px;
    left: auto; }
.kt-offcanvas-panel--right .kt-offcanvas-panel.kt-offcanvas-panel--on {
    -webkit-transition: left 0.3s ease, right 0.3s ease;
    transition: left 0.3s ease, right 0.3s ease;
    right: 0;
    left: auto; }

.kt-offcanvas-panel--right .kt-offcanvas-panel-close {
    left: auto;
    right: -25px; }

.kt-offcanvas-panel--on.kt-offcanvas-panel--right .kt-offcanvas-panel-close {
    left: auto;
    right: 399px; }