.files input {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 90px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
}
.files input:focus{     outline: 2px dashed #92b0b3;  outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear; border:1px solid #92b0b3;
}
.files{ position:relative}
.files:after {  pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    content: "";
    background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
}
.color input{ background-color:#f1f1f1;}
.files:before {
    position: absolute;
    bottom: -10px;
    left: 0;  pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: " or drag it here. ";
    display: block;
    margin: 0 auto;
    color: #2ea591;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
}

.tableHead {
    background: #BCDFEF;
}

.newTable {
    margin-right: auto;
    table-layout: fixed;
    font-size: 13.5px;
}

.tableHeading {
    padding: 10px;
    color: darkblue;
}

.firstHeading {
    padding-left: 10px;
    border-radius: 15px 0 0 0;
}

.lastHeading {
    padding-right: 5px;
    border-radius: 0 15px 0 0;
}

.tableData {
    padding: 10px;
    color: black;
}

.firstData {
    padding-left: 10px;
}

.tableStatus {
    vertical-align: middle;
    text-align: center;
}

.page-item.active .page-link {
    background: #2d5c6f;
    border-color: darkblue
}

.page-item:first-child .page-link:hover {
    background: #2d5c6f;
    border-color: darkblue
}

.page-item:last-child .page-link:hover {
    background: #2d5c6f;
    border-color: darkblue
}

.pagination li a:hover {
    background: #2d5c6f;
}

.includedInstitution {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.activePage {
    color: white;
    background: #67C4EF;
    border-color: darkblue
}

.defaultBtn {
    border-radius: 20px !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
}
