td {
    word-wrap: break-word;
}

.searchInstitutionsInputStyle {
    border-radius: 20px;
    border-style: none;
    box-shadow: 0 0 4px #1C4857;
}

.InstitutionAdminActionButton {
    float: left;
    margin-left: 5px;
    width: 110px;
    height: 35px;
    opacity: 1 !important;
}

.instName {
    width: 33%
}

.addressName {
    width: 22%
}

.phoneName {
    width: 7%
}

.infoName {
    width: 13%
}

.directorName .directorLast .responsibleName .actionsName .deleteName {
    width: 5%
}

.tableHead {
    background: #BCDFEF;
}

.newTable {
    margin-right: auto;
    table-layout: fixed;
    font-size: 13.5px;
}

.tableHeading {
    padding: 10px;
    color: darkblue;
}

.firstHeading {
    padding-left: 10px;
    border-radius: 15px 0 0 0;
}

.lastHeading {
    padding-right: 5px;
    border-radius: 0 15px 0 0;
}

.tableData {
    padding: 10px;
    color: black;
}

.firstData {
    padding-left: 10px;
}

.tableStatus {
    vertical-align: middle;
    text-align: center;
}

.page-item.active .page-link {
    background: #2d5c6f;
    border-color: darkblue
}

.page-item:first-child .page-link:hover {
    background: #2d5c6f;
    border-color: darkblue
}

.page-item:last-child .page-link:hover {
    background: #2d5c6f;
    border-color: darkblue
}

.pagination li a:hover {
    background: #2d5c6f;
}

.includedInstitution {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.activePage {
    color: white;
    background: #67C4EF;
    border-color: darkblue
}

.defaultBtn {
    border-radius: 20px !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
}

.defaultBtnAdd {
    border-radius: 20px !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    /*background: #03DAC5 !important;*/
    /*color: black !important;*/
    color: #fff;
    background-color: #17a2b8 !important;
    border-color: #17a2b8;
}

.defaultBtnEdit {
    border-radius: 20px !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    background: #EABF32 !important;
    color: black !important;
}

.defaultBtnGenerateArchive {
    border-radius: 20px !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    background: #4f781b !important;
    color: white !important;
}

.defaultBtnAddChanged {
    border-radius: 20px !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    /*background: #03DAC5 !important;*/
    /*color: black !important;*/
    color: #fff;
    background-color: #17a2b8 !important;
    border-color: #17a2b8;
    max-width: 200px !important;
}

.defaultBtnAddSpecific {
    border-radius: 20px !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    /*background: #BCDFEF !important;*/
    /*color: black !important;*/
    color: #fff;
    background-color: #17a2b8 !important;
    border-color: #17a2b8;
    max-width: 200px !important;
}

.defaultBtnDelete {
    border-radius: 20px !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    width: 110px;
    height: 35px;
    font-weight: 400;
    font-size: 1rem;
}

.defaultBtnPreview {
    background-color: #0a6e0a;
    color: white !important;
    border: 1px solid black;
    border-radius: 20px !important;
}