.FAQMainDiv{
    /*background: rgb(224,224,224);*/
    /*background: linear-gradient(153deg, rgba(224,224,224,1) 67%, rgba(222,222,222,1) 100%);*/
    background: #e0ecf1 !important;
    border-radius: 10px;
    margin-top: 15px;
    color: #6c757d;
    word-wrap: break-word;
    padding: 10px;

    box-shadow: 0 0 2px gray;
}

.FAQQuestionRow{
    border-radius: 10px;
    border-bottom: 1px solid lightgray;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    color: #505050;
}

.pagination{
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
.page-item.active .page-link{
    background-color: #2d5c6f;
    border-color: #595d6e;
    box-shadow: none;
}

.pagination li a:hover {
    background-color: #2d5c6f;
    border-color: #595d6e;
    color: white;
}

.page-link{
    color: #6c757d;
}

.FAQAdminActionButton{
    float: right;
    margin-left: 5px;
    width: 120px;
    height: 35px;
}
