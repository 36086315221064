/* Styles for wrapping the search box */

/*.main {*/
/*    width: 50%;*/
/*    margin: 50px auto;*/
/*}*/

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.inactiveUsersTableHeaderDiv{
    border-radius: 10px;
    border-bottom: 3px solid #595d6e;
    border-top: 3px solid #595d6e;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 15px 0 15px 0;
    color: #595d6e;
    font-weight: bold;
}

.inactiveUsersTableBodyDivEven{
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px 0 10px 0;
    background-color: #aeaeae;
    color: #595d6e;
    border-radius: 10px;
}

.inactiveUsersTableBodyDivOdd{
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px 0 10px 0;
    background-color: #d9d9d9;
    color: #595d6e;
    border-radius: 10px;
}
