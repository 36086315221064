.annualReportTableHeaderDiv{
    border-radius: 10px;
    border-bottom: 3px solid #595d6e;
    border-top: 3px solid #595d6e;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 15px 0 15px 0;
    color: #595d6e;
    font-weight: bold;
}

.currentYearHeading {
    color: black;
    font-weight: bold;
}

.AnnualReportTableRowCheck {
    color: black;
}

.AnnualReportTableRowCheck:hover {
    color: red;
}

.YearPickerCol {
    z-index: 1;
}

.rdt {
    width: 250px;
    color: #595d6e !important;
}

.rdtPicker {
    color: #595d6e;
}

.annualReportStatusSubmitted {
    font-weight: bold;
    color: darkgreen;
}

.annualReportStatusInProgress {
    font-weight: bold;
    color: darkgoldenrod;
}

.AnnualReportTableRowFile a {
    color: #76001B;
    font-weight: bold;
}

.AnnualReportTableRowFile a:hover {
    color: #530114;
    font-weight: bold;
}

.newBtnClass:hover {
    color: green;
    cursor: pointer;
}