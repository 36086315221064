.pro-sidebar-inner {
    background: #11303d !important;
}

.sidebar-btn-wrapper .sidebar-btn {
    padding: 1px 35px;
    border-radius: 40px;
    background: hsla(0,0%,100%,.05);
    color: #adadad;
    text-decoration: none;
    margin: 0 auto;
    height: 35px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sidebarLogoImg{
    height: 60px;
    margin-top: 10px;
}

.sidebar-btn-wrapper{
    padding: 10px 20px 24px 20px;
}

.sidebar-btn-wrapper .sidebar-btn:hover {
    color: #d1d7e2;
}

.sidebar-btn-wrapper, .sidebar-btn-wrapper .sidebar-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pro-inner-list-item {
    background: #28404a !important;
}

.pro-item-content {
    white-space: pre-wrap !important;
}