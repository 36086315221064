.portalTitle{
    font-size: 18px;
    /*color: ;*/
}

.img-responsive {
    max-width: 100%;
}

.redText{
    color:#76001B;
}

.registrationTextDiv{
    width: 100%;
    background-color: rgba(245, 245, 245, 0.38);
    padding: 20px;
    border-radius: 10px;
}

.registrationText{
    color:black;
    font-size: 14px;
    font-family: Poppins, Helvetica, sans-serif;
    text-align: justify;
}
