.QuestionMainDiv {
    /*background: rgb(224,224,224);*/
    /*background: linear-gradient(153deg, rgba(224,224,224,1) 67%, rgba(222,222,222,1) 100%);*/
    background: #e0ecf1;

    border-radius: 10px;
    margin-top: 15px;
    color: #6c757d;
    word-wrap: break-word;
    padding: 10px;

    box-shadow: 0 0 2px gray;
}

.QuestionRow {
    border-radius: 10px;
    border-bottom: 1px solid lightgray;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    color: #505050;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.page-item.active .page-link {
    background-color: #2d5c6f;
    border-color: #595d6e;
    box-shadow: none;
}

.pagination li a:hover {
    background-color: #2d5c6f;
    border-color: #595d6e;
    color: white;
}

.page-link {
    color: #6c757d;
}

.QuestionAdminActionButton {
    float: right;
    margin-left: 5px;
    width: 110px;
    height: 35px;
    opacity: 1 !important;
}

.opac {
    opacity: 0.4;
}

.publicationDivContainer {
    background-color: #d9d9d9;
    height: 150px;
    border-radius: 20px;
    margin-top: 30px;
    box-shadow: 0 0 3px gray;
    display: contents;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.publicationDivContainerDark {
    background-color: #a4a3a3;
    height: 150px;
    border-radius: 20px;
    margin-top: 30px;
    box-shadow: 0 0 3px gray;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.announcementTitleRow {
    margin: 10px 0 10px 0;
}

.announcementTitle {
    text-align: center;
    color: #1C4857;
}

.announcementBodyRow {
    margin-top: 10px;
    margin-bottom: 10px;
}

.announcementBody {
    color: #343a40;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 110ch;
    font-size: 14px;
}

.announcementLinkButton {
    margin-left: 5px;
    color: darkblue;
    height: 100%;
}


.announcementLinkButton:hover {
    color: black !important;
    height: 100%;
}

.announcementFooterRow {
    height: 50px;
    width: 99% !important;
    border-top: 1px solid dimgrey;
    border-radius: 10px;
}

.badgeText {
    font-size: 11px;
    padding: 5px 10px 5px 10px !important;
    margin-top: 3px;
}

.announcementFooterCol {
    padding-left: 10px !important;
    margin-top: -20px !important;
}

.pagination {
    padding-top: 20px;
    width: 100%;
}