header {
  margin: 10px;
}
#maxWidth {
  max-width: 600px;
}
.kt-portlet{
  /*width: 1650px !important;*/
}
#marginRight {
    margin-right: 4px;
}
.pagination {
  display:block;
  list-style:none;
  margin:0;
  padding:0;
}
.pagination li {
  display:inline-block;
}
.pagination li a {
  display:block;
  margin:0px 2px 10px 0px;
  padding:8px 14px;
  border-radius:3px;
}
.pagination li a:hover{
  text-decoration:none;
  cursor:pointer;
  background:#007bff;
  box-shadow:rgba(0,0,0,0.1) 0px 5px 25px;
}
.pagination li a:focus{
  outline:none;
}
.pagination li.active a {
    color: #fff;
    background: #ED254E;
}

.pagination li.disabled a {
    color: #fff;
    background: #555;
    opacity: 0.25;
    cursor: default;
}


.tableHead {
    background: #BCDFEF;
}

.newTable {
    margin-right: auto;
    table-layout: fixed;
    font-size: 13.5px;
}

.tableHeading {
    padding: 10px;
    color: darkblue;
}

.firstHeading {
    padding-left: 10px;
    border-radius: 15px 0 0 0;
}

.lastHeading {
    padding-right: 5px;
    border-radius: 0 15px 0 0;
}

.tableData {
    padding: 10px;
    color: black;
}

.firstData {
    padding-left: 10px;
}

.tableStatus {
    vertical-align: middle;
    text-align: center;
}

.page-item.active .page-link {
    background: #2d5c6f;
    border-color: darkblue
}

.page-item:first-child .page-link:hover {
    background: #2d5c6f;
    border-color: darkblue
}

.page-item:last-child .page-link:hover {
    background: #2d5c6f;
    border-color: darkblue
}

.pagination li a:hover {
    background: #2d5c6f;
}

.includedInstitution {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.activePage {
    color: white;
    background: #67C4EF;
    border-color: darkblue
}

.defaultBtn {
    border-radius: 20px !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
}
