html,body{
    height: calc( 100vh - 114px );
    margin: 0 !important;
    padding: 0 !important;
}

#root{
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
}
.App {
    text-align: center;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    /*min-height: 100vh;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.4) !important;
}

.Toastify__toast {
    color: white;
    font-weight: bold;
    font-size: 15px;
}

.Toastify__toast--success {
    color: white;
    font-weight: bold;
}

.Toastify__toast--error {
    color: white;
    font-weight: bold;
}