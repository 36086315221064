#box {
    background-color: #d9d9d9;
    padding: 10px;
    margin-top: 30px;
    border-radius: 20px;
    box-shadow: 0 0 3px steelblue;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/*Graph No*/
/*#SvgjsPath1017{*/
/*    fill: darkred !important;*/
/*}*/

/*Graph Yes*/
/*#SvgjsPath1022{*/
/*    fill: #5578eb !important;*/
/*}*/
