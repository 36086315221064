#idBtnReset {
    display: inline;
    width: 100%;
    border-radius: 10px;
    color: white;
    background-color: #1515a9;
    font-size: 15px;
    opacity: 0.2;
}


#idBtnReset:hover {
    display: inline;
    width: 100%;
    border-radius: 10px;
    color: white;
    background-color: darkblue;
}

#idBtnLogin {
    display: inline;
    width: 100%;
    border-radius: 10px;
    color: white;
    background-color: #42a9b9;
    font-size: 15px;
}


#idBtnLogin:hover {
    display: inline;
    width: 100%;
    border-radius: 10px;
    color: white;
    background-color: #1596a9;
}