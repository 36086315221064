
.modal-body {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}

.modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    max-width: 50%;
    max-height: 1000px;
    /*max-height: 90%;*/
}

.modal-title {
    font-weight: 600 !important;
    color: black;
}