td {
    word-wrap: break-word;
}

.searchInstitutionsInputStyle {
    border-radius: 20px;
    border-style: none;
    box-shadow: 0 0 4px #1C4857;
}

.InstitutionAdminActionButton {
    float: left;
    margin-left: 5px;
    width: 110px;
    height: 35px;
    opacity: 1 !important;
}

.select__indicator.select__clear-indicator.css-tlfecz-indicatorContainer {
    display: none;
}

.go1003880112.clear-selected-button {
    display: none;
}

.dropdown-heading-dropdown-arrow.gray {
    display: none;
}

.select__indicator.select__dropdown-indicator.css-tlfecz-indicatorContainer {
    display: none;
}

.instName {
    width: 27%
}

.addressName {
    width: 23%
}

.phoneName {
    width: 7%
}

.infoName {
    width: 13%
}

.directorName .directorLast .actionsName .deleteName {
    width: 5%
}

.tableHead {
    background: #BCDFEF;
}

.newTable {
    margin-right: auto;
    table-layout: fixed;
    font-size: 13.5px;
}

.tableHeading {
    padding: 10px;
    color: darkblue;
}

.firstHeading {
    padding-left: 10px;
    border-radius: 15px 0 0 0;
}

.lastHeading {
    padding-right: 5px;
    border-radius: 0 15px 0 0;
}

.tableData {
    padding: 10px;
    color: black;
}

.firstData {
    padding-left: 10px;
}

.tableStatus {
    vertical-align: middle;
    text-align: center;
}

.page-item.active .page-link {
    background: #2d5c6f;
    border-color: darkblue
}

.page-item:first-child .page-link:hover {
    background: #2d5c6f;
    border-color: darkblue
}

.page-item:last-child .page-link:hover {
    background: #2d5c6f;
    border-color: darkblue
}

.pagination li a:hover {
    background: #2d5c6f;
}

.includedInstitution {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.activePage {
    color: white;
    background: #67C4EF;
    border-color: darkblue
}

.defaultBtn {
    border-radius: 20px !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
}